<template>
  <div class="flex items-center justify-center w-full h-full">
    <div>
      <h4 v-if="(campaigns.length > 0)">
        Select a campaign
      </h4>
      <h4 v-else>
        No campaings
      </h4>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({ campaigns: 'CAMPAIGNS/getCampaigns' })
  }
}
</script>

<style>

</style>
